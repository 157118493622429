var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-container', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_vm.$options.components['profile-need-help'] ? [_c('profile-need-help')] : _vm._e()], 2), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('h3', {
    staticClass: "heading"
  }, [_vm._v("FAQ")]), _vm.faqData ? _c('div', {
    staticClass: "faq-maindv mt-4"
  }, _vm._l(_vm.faqData, function (item, i) {
    return _c('div', {
      key: i
    }, _vm._l(item, function (each, id) {
      return _c('div', {
        key: id,
        staticClass: "mb-3"
      }, [_c('div', {
        staticClass: "d-flex justify-content-between question-background"
      }, [_c('p', {
        staticClass: "question mb-0"
      }, [_vm._v("Q: " + _vm._s(each.topic))]), _c('b-icon', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: each._id !== _vm.open,
          expression: "each._id !== open"
        }],
        attrs: {
          "stacked": "",
          "icon": "chevron-right",
          "shift-h": "0",
          "id": "arrow-down-black",
          "value": each._id,
          "scale": "0.5"
        },
        on: {
          "click": function click($event) {
            return _vm.isFindIndex(each._id, _vm.faqData);
          }
        }
      }), _c('b-icon', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: each._id === _vm.open,
          expression: "each._id === open"
        }],
        attrs: {
          "stacked": "",
          "icon": "chevron-down",
          "shift-h": "0",
          "id": "arrow-down",
          "scale": "0.5"
        },
        on: {
          "click": function click($event) {
            _vm.open = false;
          }
        }
      })], 1), _c('p', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: each._id === _vm.open,
          expression: "each._id === open"
        }],
        staticClass: "answer"
      }, [_vm._v(" " + _vm._s(each.description) + " ")])]);
    }), 0);
  }), 0) : _vm._e()])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }